import React from 'react';

const SelfEmployed = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>The most common types of self-employed are sole traders, Partners or Director of a company</li>
              <li>
              If you’re a sole trader, what shows on your tax return as your total taxable income is what lenders will use to calculate your mortgage
              </li>
              <li>
              If you’re part of a partnership set-up (LLP) or a Director, some lenders take a different approach to how much you could earn so it’s best to speak to an adviser
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              Things to consider if you're self-employed.<br/><br/>
              There are several types of self-employment in the UK. The most common are as a sole trader, a partner and being the director of a limited company.<br /><br />
              If you are a sole trader, then whatever shows on your tax return as your total taxable income is what lenders will typically use as to what you've earned. And that's what they use to determine how much of a mortgage they will lend to you.<br /><br />
              If you're part of a partnership set up, sometimes known as an LLP, which stands for Limited Liability Partnership, then its likely that you will be paying yourself the equivalent of a salary by way of drawings and then sometimes leaving money in the business as well, as opposed to taking it all out as drawings. Similarly, if you are the director of your own limited company, it is likely that you may make your regular salary payments and also take dividends out of the company.<br /><br />
              It's important to understand that as a sole trader, what shows as your income and your tax return is your income. That's not necessarily the case if you're a partner of a director. Often to be tax efficient, accountants will recommend a particular amount of salary and a particular amount of dividends. But of course, if you don't pay yourself the maximum you can, in theory, you're going to impact how much you can borrow on a mortgage.<br /><br />
              What is important to know is that this is not necessarily the case. Certain lenders can take a view on how much you could have paid yourself.<br /><br />
              For example, a director of his own limited company pays himself a salary of £10,000 and dividends of £20,000. So to bank A he earns £30,000, but to bank B he earns a lot more. This is because Bank B asks about how much shareholding he has in the company.<br /><br />
              In this example, he owns 100% of his own company and that company made £80,000 in profit in the last few years. So, therefore, to bank B, his income is a lot higher and therefore he can get a lot more of it as a mortgage.<br /><br />
              Policies on this sort of thing can change all of the time. So it is important to speak to a mortgage adviser to understand all of the options specifically available to you.<br /><br />
            </p>
          </div>
    </div>
  );
};

export default SelfEmployed;
